import React from 'react';
import PropTypes from 'prop-types';

import './css/Board.css';
import './css/loading.scss';
import './css/Cursor.scss';
import charToCheck from './App.js';

const Board = ({
    arrayLettersFind, arrayLetters, nombreTry, maxTry, isGameStarted, isWon
}) => (
    <div className="screen-board">
        {
            <div className={`screen-try ${isGameStarted ? 'show' : ''}`}>{nombreTry} / {maxTry} essaies</div>
        }
        {
            <div className={`loading-game loading-game-${!isWon || isWon === null ? 'lost' : 'won'} ${isGameStarted && isWon === null ? 'hidden' : ''}`}></div>
        }
        <div className={`ligne-letter ${isGameStarted ? 'show' : ''}`}>
            {                   
                arrayLetters.map((letter, index) => {
                    if(charToCheck.charToCheck.includes(letter) && isWon == null){
                        letter = letter;
                        if(!arrayLettersFind.includes(letter)){
                            arrayLettersFind.push(letter);
                        }
                    }else if (!arrayLettersFind.includes(letter) && isWon === null) {
                        letter = '_';
                    }
                    return (<div key={index} className={`find-letter ${!isWon && !arrayLettersFind.includes(letter) ? 'error-letter' : ''}`}>{letter}</div>);
                })
            }
        </div>
    </div>
);

Board.propTypes = {
    arrayLetters: PropTypes.array.isRequired,
    arrayLettersFind: PropTypes.array,
    nombreTry: PropTypes.number.isRequired,
    maxTry: PropTypes.number.isRequired,
    isGameStarted: PropTypes.bool.isRequired,
    isWon: PropTypes.bool
};

export default Board;
