import React from 'react';
import PropTypes from 'prop-types';

import './css/Letter.css';

const Letter = ({
    index, value, className, onClickCallBack
}) => (
    <div className={`letter ${className}`} onClick={() => onClickCallBack(index)}>
        <span className="letter-symbol">
            {value}
        </span>
    </div>
);

Letter.propTypes = {
    index: PropTypes.number.isRequired,
    className: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onClickCallBack: PropTypes.func.isRequired
};

export default Letter;
