import React, { Component } from 'react';

import './css/App.css';
import './css/Cursor.scss';
import Dic from './dictionnaire/dictionnaire';

import Clavier from './Clavier';
import Display from './Display';
import Board from './Board';

const dic = Dic;
const phaseInGame = [4, 5, 6, 7];
const maxTry = 9;
const timeOutTime = 3500; 

const timeOutArray = [];

const arrayMoreKey = ['Escape'];

class App extends Component {
    static charToCheckRegex = "/[ '-]/g";
    static charToCheck = ["'"," ","-"];
    constructor() {
        super();
        this.state = {
            phase: 0,
            arrayPhase: {
                0: [
                    '<span>Bonjour et bienvenue dans le jeux du pendu !</span><br><span>Voulez-vous commencer une partie ?</span><br><span style="margin-right : 15px;">(O)Oui</span><span>(N)on</span>'
                ],
                1: [
                    '<span>Sélection du mot en cours ...</span>'
                ],
                2: [],
                3: [
                    '<span>Merci d\'avoir joué au jeu du pendu :)</span><br><span>Bonne journée !</span>'
                ],
                4: ['<span>Veuillez saisir ou cliquer sur une lettre.'],
                5: ['<span>Désolé vous avez perdu !</span><br><span>Voici la/les lettre(s) qu\'il vous manquait ;)'],
                6: ['<span>Félication vous avez trouvé le mot :D'],
                7: ['<span>Voulez-vous rejouer ? (O ou N)']
            },
            wordToFind: '',
            arrayLettersFind: [],
            arrayLettersDone: [],
            nombreTry: 0,
            isWon: null
        };
    }

    handleUserResponse = (letter) => {
        const {
            phase, arrayPhase, arrayLettersFind, arrayLettersDone, wordToFind, nombreTry
        } = this.state;
        let refreshClavier = false;

        if (arrayMoreKey.includes(letter)) {
            timeOutArray.map((objTimeOut) => {
                clearTimeout(objTimeOut);
                return true;
            });
            if (letter === 'Escape') {
                this.setState({
                    phase: 0,
                    wordToFind: '',
                    arrayLettersFind: [],
                    arrayLettersDone: [],
                    nombreTry: 0,
                    isWon: null
                });
                return true;
            }
        }

        switch (phase) {
            case 0:
                if (letter.toUpperCase() === 'O') {
                    // choix de joueur pour jouer
                    refreshClavier = true;
                    // choix de joueur pour jouer
                    this.setState({ phase: 1 });
                    const word = dic[Math.floor(Math.random() * Object.keys(dic).length)].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
                    var nbSpecialChar = word.match(this.charToCheck);
                    var wordLength = word.length - nbSpecialChar.length;
                    // affiche dans un premier temps la phase 2
                    timeOutArray.push(setTimeout(() => {
                        arrayPhase[2] = [
                            `<span>Le mot est composé de ${wordLength} lettres</span><br><span>A vous de jouer !!!</span>`
                        ];
                        this.setState({
                            phase: 2,
                            arrayPhase
                        });
                        // affichage dans une deuxième temps la phase 5
                        timeOutArray.push(setTimeout(() => {
                            // on commencer la partie
                            this.setState({
                                phase: 4,
                                wordToFind: word
                            });
                        }, timeOutTime));
                    }, timeOutTime));
                } else if (letter.toUpperCase() === 'N') {
                    // sortie du jeux
                    this.setState({ phase: 3 });
                }
                break;
            case 4:
                if (!arrayLettersDone.includes(letter)) {
                    if (wordToFind.indexOf(letter) !== -1) {
                        const arrayTmp = [];
                        // vérification si la lettre est présente plusieurs fois dans le mots
                        const nbLettersInWord = wordToFind.match(new RegExp(letter, 'g')).length;
                        if (nbLettersInWord > 1) {
                            for (let index = 0; index < nbLettersInWord;) {
                                arrayTmp.push(letter);
                                index += 1;
                            }
                        } else {
                            arrayTmp.push(letter);
                        }
                        console.log([...arrayLettersFind, ...arrayTmp]);
                        console.log(wordToFind);
                        console.log(wordToFind.length);
                        console.log([...arrayLettersFind, ...arrayTmp].length);
                        if (wordToFind.split('').length === [...arrayLettersFind, ...arrayTmp].length) {
                            // WIN
                            refreshClavier = true;
                            this.setState({ phase: 6, isWon: true });
                            timeOutArray.push(setTimeout(() => {
                                this.setState({ phase: 7 });
                            }, timeOutTime));                            
                        }
                        this.setState({
                            arrayLettersFind: [...arrayLettersFind, ...arrayTmp]
                        });
                    } else if (maxTry === nombreTry + 1) {
                        // LOOSE
                        refreshClavier = true;
                        this.setState({ phase: 5, nombreTry: nombreTry + 1, isWon: false });
                        timeOutArray.push(setTimeout(() => {
                            this.setState({ phase: 7 });
                        }, timeOutTime));                        
                    } else {
                        this.setState({ nombreTry: nombreTry + 1 });
                    }                    
                    this.setState({
                        arrayLettersDone: [...arrayLettersDone, ...letter]
                    });
                }
                break;
            case 7:
                if (letter.toUpperCase() === 'O') {
                    refreshClavier = true;
                    this.setState({ phase: 1 });
                    const word = dic[Math.floor(Math.random() * Object.keys(dic).length)].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toUpperCase();
                    // affiche dans un premier temps la phase 2
                    setTimeout(() => {
                        arrayPhase[2] = [
                            `<span>Le mot à trouver est composé de ${word.length} lettres</span><br><span>A vous de jouer !!!</span>`
                        ];
                        this.setState({
                            phase: 2,
                            arrayPhase,
                            nombreTry: 0
                        });
                        // affichage dans une deuxième temps la phase 5
                        setTimeout(() => {
                            // on commencer la partie
                            this.setState({
                                phase: 4,
                                arrayLettersFind: [],
                                arrayLettersDone: [],
                                isWon: null,
                                wordToFind: word
                            });
                        }, timeOutTime);
                    }, timeOutTime);
                } else if (letter.toUpperCase() === 'N') {
                    // sortie du jeux
                    this.setState({ phase: 3 });
                }
                break;
            default:
                break;
        }
        if (refreshClavier) {
            return false;
        }
        return (phaseInGame.includes(phase));
    }

    render() {
        const {
            phase, arrayPhase, wordToFind, arrayLettersFind, nombreTry, isWon
        } = this.state;
        return (
            <div className="container">
                <div className="display">
                    <Board
                        arrayLettersFind={arrayLettersFind}
                        arrayLetters={wordToFind.split('')}
                        nombreTry={nombreTry}
                        maxTry={maxTry}
                        isWon={isWon}
                        isGameStarted={(phaseInGame.includes(phase))}
                    />
                    <Display
                        phase={phase}
                        arrayPhase={arrayPhase}
                    />
                </div>
                <Clavier arrayMoreKey={arrayMoreKey} handleLetterCallBack={this.handleUserResponse} />
            </div>
        );
    }
}

export default App;
