import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './css/Clavier.css';
import Letter from './Letter';

class Clavier extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isModified: false,
            arrayLetters: this.getArrayLetters(),
            arrayLettersDones: []
        };
        this.handleLetterCallBack = props.handleLetterCallBack;
    }

    getClassName(index) {
        const { arrayLettersDones, isModified } = this.state;
        if (arrayLettersDones.includes(index) && isModified === true) {
            return 'clicked';
        }

        return '';
    }

    getArrayLetters = () => {
        const arrayLetters = [
            'A', 'Z', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P',
            'Q', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'M',
            'W', 'X', 'C', 'V', 'B', 'N',
        ];
        return arrayLetters;
    }

    letterOnClickCallBack = (index) => {
        const { arrayLettersDones, arrayLetters } = this.state;
        const gameStarted = this.handleLetterCallBack(arrayLetters[index]);
        this.setState({ isModified: gameStarted });
        if (!gameStarted) {
            this.setState({ arrayLettersDones: [] });
        }
        if (!this.state.arrayLettersDones.includes(index) && gameStarted) {
            this.setState({ arrayLettersDones: [...arrayLettersDones, ...[index]] });
        }
    }

    handleKeyDown = (e) => {
        const { arrayLetters } = this.state;
        const indexLetter = arrayLetters.indexOf(e.key.toUpperCase());
        if (indexLetter !== -1) {
            this.letterOnClickCallBack(indexLetter);
        } else if (this.props.arrayMoreKey.includes(e.key)) {
            this.handleLetterCallBack(e.key);
        }
    }

    componentDidMount() {
        document.addEventListener('keydown', this.handleKeyDown);
    }

    render() {
        const { arrayLetters } = this.state;
        return (
            <div className="clavier-container">
                {
                    arrayLetters.map((letter, index) => (
                        <Letter
                            index={index}
                            value={letter}
                            className={this.getClassName(index)}
                            key={index}
                            onClickCallBack={this.letterOnClickCallBack}
                        />
                    ))
                }
            </div>
        );
    }
}

Clavier.propTypes = {
    handleLetterCallBack: PropTypes.func.isRequired,
    arrayMoreKey: PropTypes.array.isRequired
};

export default Clavier;
