import React from 'react';
import PropTypes from 'prop-types';
import Typewriter from 'typewriter-effect';

import './css/Display.css';
import './css/Cursor.scss';

const Display = ({
    phase, arrayPhase
}) => (
    <div className="screen-display">
        <div className="message">
            {
                arrayPhase[phase].map((text) => (
                    <Typewriter
                        key={text}
                        options={{
                            strings: text,
                            autoStart: true,
                            loop: false,
                            delay: 40
                        }}
                    />
                ))
            }
        </div>
    </div>
);

Display.propTypes = {
    phase: PropTypes.number.isRequired,
    arrayPhase: PropTypes.object.isRequired
};

export default Display;
